import * as React from "react";
import "@aws-amplify/ui/dist/style.css";
import Teaser from "components/pages/front/Teaser";
import Main from "components/pages/front/Main";
import Layout from "components/layouts/Common";
import { isBefore, isAfter } from "date-fns";
import { Helmet } from "react-helmet";
import { GatsbySeo } from "gatsby-plugin-next-seo";

type Props = {
  pageContext: any;
};

export default function App(props: Props) {
  const { pageContext } = props;
  const { show } = pageContext;

  const [date, setDate] = React.useState(new Date());

  React.useEffect(() => {
    const interval = setInterval(() => {
      setDate(new Date());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      {show && (
        <GatsbySeo
          title={show.title}
          description={show.description}
          openGraph={{
            url: `https://www.onlineboatshow.jp/${show.slug}`,
            title: show.title,
            description: show.description,
            site_name: "ONLINE BOATSHOW　オンラインボートショー",
            images: [
              {
                url: show.ogpImage ? show.ogpImage.url : "",
                alt: show.title,
              },
            ],
          }}
        />
      )}
      <Helmet>
        <title>{`${show.title} ONLINE BOATSHOW　オンラインボートショー`}</title>
      </Helmet>
      <div className="App">
        <Layout show={show}>
          {isBefore(date, new Date(pageContext.show.startDateTime)) && (
            <Teaser show={pageContext.show} />
          )}

          {isAfter(date, new Date(pageContext.show.startDateTime)) && (
            <Main show={pageContext.show} />
          )}
        </Layout>
      </div>
    </>
  );
}
