import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import renderHTML from "react-render-html";
// @material-ui/icons
import { NoSsr } from "@material-ui/core";

// core components
import GridContainer from "components/uikit/Grid/GridContainer.js";
import GridItem from "components/uikit/Grid/GridItem.js";
// import Button from "components/uikit/CustomButtons/Button.js";
import Parallax from "components/uikit/Parallax/Parallax.js";
import CountDown from "components/atoms/CountDown";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
// Sections for this page
import ProductSection from "./Sections/HtmlSection.js";
import { API, graphqlOperation } from "aws-amplify";
import { listTeasers } from "graphql/queries";

const useStyles = makeStyles(styles);

export default function Teaser(props) {
  const classes = useStyles();
  const { show } = props;
  const [data, setData] = React.useState();

  React.useEffect(() => {
    async function f() {
      const res = await API.graphql(
        // @ts-ignore
        graphqlOperation(listTeasers, {
          filter: {
            showID: { eq: show.showID },
          },
        })
      );
      // @ts-ignore
      setData(res.data.listTeasers.items[0]);
    }
    f();
  }, [show.showID]);

  return (
    <div>
      {show && data && (
        <>
          <Parallax
            filter
            image={data.backgroundImage ? data.backgroundImage.url : null}
          >
            <div className={classes.container}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={8}>
                  <h1 className={classes.title}>{renderHTML(data.title)}</h1>

                  {data.countDown && (
                    <>
                      <h2>オンライン版ボートショー開催まで</h2>

                      <CountDown date={new Date(show.startDateTime)} />
                    </>
                  )}

                  {data.discription && (
                    <div className={classes.discription}>
                      {renderHTML(data.discription)}
                    </div>
                  )}
                </GridItem>
              </GridContainer>
            </div>
          </Parallax>
          <div className={classNames(classes.main, classes.mainRaised)}>
            <div className={classes.container}>
              <ProductSection data={data.section} />
              {/* <TeamSection />
          <WorkSection /> */}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
